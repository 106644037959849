/* src/index.css */
@tailwind base;
@tailwind components;
@tailwind utilities;

/* src/index.css */

.animation-delay-2000 {
  animation-delay: 2s;
}

html {
  scroll-behavior: smooth;
}

/* Custom Dark Mode Styles */
body {
  @apply bg-white text-gray-800;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen,
    Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.dark body {
  @apply bg-gray-900 text-gray-100;
}